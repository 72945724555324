import React, {useState, useEffect} from 'react';
import {ThreeDots} from 'react-loader-spinner';
import {sendRequest} from './api';
import './TextSender.css';

function TextSender({initialText, setShowRecorderControls}) {
    const [text, setText] = useState(initialText || '');
    const [result, setResult] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setText(initialText);
    }, [initialText]);

    const handleButtonClick = async () => {
        if (text.trim() === '') {
            alert('질문을 입력해주세요.');
            return;
        }
        setIsLoading(true);
        try {
            const response = await sendRequest('https://geoai.snu.ac.kr/total', {question: text});
            console.log('Result: ', response);
            setResult(JSON.stringify(response, null, 2));
        } catch (error) {
            // Handle error
        } finally {
            setIsLoading(false);
        }
    };

    const handleTextChange = (e) => {
        setText(e.target.value);
    };

    const handleMicClick = () => {
        setShowRecorderControls(prevState => !prevState);
    };

    return (
        <div className="text-sender">
            <div className="input-area">
                <input
                    id="text-input"
                    type="text"
                    value={text}
                    onChange={handleTextChange}
                    placeholder="질문을 입력해주세요"
                />
                <button onClick={handleMicClick}>🎤</button>
                <button onClick={handleButtonClick} disabled={isLoading}>&#128269;</button>
            </div>
            {isLoading ? <ThreeDots color="#2BAD60" height="100" width="100"/> : null}
            {result && (
                <div className="results">
                    <h2>답변</h2>
                    <pre id="text-result">{result}</pre>
                </div>
            )}
        </div>
    );
}

export default TextSender;
