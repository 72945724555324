import axios from 'axios';

export async function sendRequest(url, data, isMultipart = false, method = 'POST') {
    try {
        const response = await axios({
            url,
            method,
            data: isMultipart ? data : JSON.stringify(data),
            headers: {'Content-Type': isMultipart ? 'multipart/form-data' : 'application/json'},
        });
        return response.data;
    } catch (error) {
        console.error(`Error: ${error}`);
        alert('An error occurred while processing your request');
        throw error;
    }
}
