import React, {useState} from 'react';
import {AudioRecorder, useAudioRecorder} from 'react-audio-voice-recorder';
import {ThreeDots} from 'react-loader-spinner';
import {sendRequest} from './api';
import './VoiceRecorder.css';

function VoiceRecorder({setSttText, showRecorderControls, setShowRecorderControls}) {
    const [isRecording, setIsRecording] = useState(false);
    const [selectedFileName, setSelectedFileName] = useState('');
    const recorderControls = useAudioRecorder();
    const [isLoading, setIsLoading] = useState(false);

    const sendFileToServer = async (file) => {
        setIsLoading(true);
        const formData = new FormData();
        formData.append('file', file);
        try {
            const data = await sendRequest('https://geoai.snu.ac.kr/stt', formData, true);
            console.log('Transcript: ', data);
            setSttText(data.stt);
        } catch (error) {
            // Handle error
        } finally {
            setIsLoading(false);
            setShowRecorderControls(false);
        }
    };

    const handleRecordingComplete = async (blob) => {
        const file = new File([blob], "recording.webm", {type: "audio/webm"});
        console.log(file.type);

        await sendFileToServer(file);
    };

    const handleRecording = () => {
        if (!isRecording) {
            recorderControls.startRecording();
            setIsRecording(true);
        } else {
            recorderControls.stopRecording();
            setIsRecording(false);
        }
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (!file) {
            console.log('No file selected');
            return;
        }

        setSelectedFileName(file.name);
        await sendFileToServer(file);
    };

    return (
        <div className="voice-recorder">
            {showRecorderControls && (
                <div className="controls">
                    <div className="recording-controls">
                        <AudioRecorder
                            onRecordingComplete={handleRecordingComplete}
                            recorderControls={recorderControls}
                        />
                        <button onClick={handleRecording}>{isRecording ? 'Stop recording' : 'Start recording'}</button>
                    </div>
                    <input type="file" id="fileUpload" onChange={handleFileChange} style={{display: 'none'}}/>
                    <label htmlFor="fileUpload" className="custom-file-upload">{selectedFileName || 'Choose a file'}</label>
                </div>
            )}
            {isLoading ? <ThreeDots color="#2BAD60" height="100" width="100"/> : null}
        </div>
    );
}

export default VoiceRecorder;
