import React, {useState} from 'react';
import VoiceRecorder from './components/VoiceRecorder';
import TextSender from './components/TextSender';
import './App.css';

function App() {
    const [sttText, setSttText] = useState('');
    const [showRecorderControls, setShowRecorderControls] = useState(false);

    return (
        <div className="app">
            <header className="app-header">
                <h1>공간지식추론엔진 DEMO</h1>
            </header>
            <TextSender initialText={sttText} setShowRecorderControls={setShowRecorderControls} />
            <VoiceRecorder setSttText={setSttText} showRecorderControls={showRecorderControls} setShowRecorderControls={setShowRecorderControls} />
        </div>
    );
}

export default App;
